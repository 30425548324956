<template>
  <FoldableCard :title="t['79']">
    <template #filterbody>
      <div class='p-fluid formgrid grid hr-line-dashed-bottom mr-1'>
        <div class='col-12 md:col-4 mb-2 mt-1'>
          <label for='tidfilter' class='font-bold'>{{ t['466'] }}</label>
          <div id='tidfilter' class='grid col-12 p-inputgroup mt-0'>
            <Button @click='toggleIdFilterOperator' style='width: 90px'>
              <span class='p-button-label'>{{ idFilterOperator }}</span>
            </Button>
            <InputText :placeholder="t['466'] + '...'" class='col-12' v-model='idFilterValue'></InputText>
          </div>
        </div>
        <div class='col-12 md:col-5 mb-2 mt-1'>
          <label for='namefilter' class='font-bold'>{{ t['431'] }}</label>
          <div id='namefilter' class='grid col-12 p-inputgroup mt-0'>
            <Button @click='toggleNameFilterOperator' style='width: 90px'>
              <span class='p-button-label'>{{ nameFilterOperator }}</span>
            </Button>
            <InputText :placeholder="t['431'] + '...'" class='col-12' v-model='nameFilterValue'></InputText>
          </div>
        </div>
      </div>
    </template>
    <template #filterfooter>
      <div class='mt-2'>
        <Button class="p-button-outlined mr-2" @click='clearFilter' :label="t['494']"></Button>
        <Button class="p-button" @click='applyFilter' :label="t['78'] + ' ' + t['79']"></Button>
      </div>
    </template>
  </FoldableCard>
  <div class='card'>
    <h5 class='font-bold hr-line-dashed-bottom'>{{ t['467'] }}</h5>
    <DataTable :value="tableList" :paginator='true' :rows='20' :rowsPerPageOptions='[10, 20, 50]' class='p-datatable-sm'
               v-model:selection='tableSelection' selectionMode='single' :loading='loading'>
      <Column field="tid" :header="t['466']" headerStyle='width: 20%' :sortable='true'></Column>
      <Column field="name" :header="t['468']" headerStyle='width: 70%'></Column>
      <Column headerStyle='width: 10%'>
        <template #body='slotProps'>
          <i class='pi pi-pencil' @click='openEditTable(slotProps.data.tid)'  style='font-size: 1rem'></i>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { usePrimeVue } from 'primevue/config';
import { computed } from 'vue';
import axios from 'axios';

export default {
  name: 'Tables',
  setup() {
    const store = useStore()
    const primeVue = usePrimeVue()

    return {
      token: computed(() => store.state.bearerToken),
      t: primeVue.config.locale,
    }
  },
  data() {
    return  {
      loading: true,
      tableList: [],
      tableSelection: null,
      idFilterOperator: '=',
      idFilterList: ['>', '>=', '<', '<=', '='],
      idFilterValue: '',
      nameFilterOperator: '=',
      nameFilterList: ['=', '!=', 'LIKE', 'NOT LIKE'],
      nameFilterValue: '',
    }
  },
  mounted() {
    this.getTableList()
  },
  created() {

  },
  watch: {
    '$route'(to) {
      this.tableId = to.params.parameterTableId
    },
  },
  methods: {
    getTableList() {
      this.loading = true
      axios
        .post( '/administration/param/table/list',  {
              criteria:
                  [
                    {fn: "ptbid", eq: this.idFilterOperator, fv: this.idFilterValue},
                    {fn: "ptbaccess", eq: "IN",	fv:[1,2,3]},
                    {fn: "tptname", eq: this.nameFilterOperator, fv: this.nameFilterValue}
                  ],
              orderByField:"ptbid",
              direction:"ASC"
            },
            {
              headers: {
                Authorization: this.token
              }
            })
        .then( res => {
          if(res.status === 200 && res.data.rmsList[0].retCode === 0){
            this.tableList = res.data.retObj
            this.loading = false
          }else {
            this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
          }
        })
        .catch(error => {
          console.log("There was an error loading parameter table list!")
          console.log(error)
        })
    },
    openEditTable(tableId) {
      this.$router.push('/editParamTable/'+tableId)
    },
    toggleIdFilterOperator() {
      let index = this.idFilterList.indexOf(this.idFilterOperator) + 1
      let newIndex = (index%(this.idFilterList.length))
      this.idFilterOperator = this.idFilterList[newIndex]
    },
    toggleNameFilterOperator() {
      let index = this.nameFilterList.indexOf(this.nameFilterOperator) + 1
      let newIndex = (index%(this.nameFilterList.length))
      this.nameFilterOperator = this.nameFilterList[newIndex]
    },
    applyFilter() {
      this.getTableList()
    },
    clearFilter() {
      this.idFilterOperator = '='
      this.idFilterValue = ''
      this.nameFilterOperator = '='
      this.nameFilterValue = ''
    }
  },
};
</script>

<style scoped lang='scss'>

.hr-line-dashed-bottom {
  border-bottom: 1px dashed lightgray;
}

</style>